const leagueSeasons = [
  {
    leagueKey: "NCBB",
    seasons: [
      { seasonKey: 5, seasonId: "ncbb-season-5", current: false },
      { seasonKey: 6, seasonId: "ncbb-season-6", current: false },
      { seasonKey: 7, seasonId: "ncbb-season-7", current: false },
      { seasonKey: 8, seasonId: "ncbb-season-8", current: false },
      { seasonKey: 9, seasonId: "ncbb-season-9", current: false },
      { seasonKey: 10, seasonId: "ncbb-season-10", current: false },
      { seasonKey: 11, seasonId: "ncbb-season-11", current: false },
      { seasonKey: 12, seasonId: "ncbb-season-12", current: false },
      { seasonKey: 13, seasonId: "ncbb-season-13", current: false },
      { seasonKey: 14, seasonId: "ncbb-season-14", current: false },
      { seasonKey: 15, seasonId: "ncbb-season-15", current: false },
      { seasonKey: 16, seasonId: "ncbb-season-16", current: false },
      { seasonKey: 17, seasonId: "ncbb-season-17", current: false },
      { seasonKey: 18, seasonId: "ncbb-season-18", current: false },
      { seasonKey: 19, seasonId: "ncbb-season-19", current: false },
      { seasonKey: 20, seasonId: "ncbb-season-20", current: false },
      { seasonKey: 21, seasonId: "ncbb-season-21", current: false },
      { seasonKey: 22, seasonId: "ncbb-season-22", current: false },
      { seasonKey: 23, seasonId: "ncbb-season-23", current: false },
      { seasonKey: 24, seasonId: "ncbb-season-24", current: false },
      { seasonKey: 25, seasonId: "ncbb-season-25", current: false },
      { seasonKey: 26, seasonId: "ncbb-season-26", current: false },
      { seasonKey: 27, seasonId: "ncbb-season-27", current: false },
      { seasonKey: 28, seasonId: "ncbb-season-28", current: false },
      { seasonKey: 29, seasonId: "ncbb-season-29", current: false },
      { seasonKey: 30, seasonId: "ncbb-season-30", current: false },
      { seasonKey: 31, seasonId: "ncbb-season-31", current: false },
      { seasonKey: 32, seasonId: "ncbb-season-32", current: false },
      { seasonKey: 33, seasonId: "ncbb-season-33", current: false },
      { seasonKey: 34, seasonId: "ncbb-season-34", current: false },
      { seasonKey: 35, seasonId: "ncbb-season-35", current: false },
      { seasonKey: 36, seasonId: "ncbb-season-36", current: false },
      { seasonKey: 37, seasonId: "ncbb-season-37", current: false },
      { seasonKey: 38, seasonId: "ncbb-season-38", current: false },
      { seasonKey: 39, seasonId: "ncbb-season-39", current: false },
      { seasonKey: 40, seasonId: "ncbb-season-40", current: false },
      { seasonKey: 41, seasonId: "ncbb-season-41", current: false },
      { seasonKey: 42, seasonId: "ncbb-season-42", current: false },
      { seasonKey: 43, seasonId: "ncbb-season-43", current: false },
      { seasonKey: 44, seasonId: "ncbb-season-44", current: false },
      { seasonKey: 45, seasonId: "ncbb-season-45", current: false },
      {
        seasonKey: 46,
        seasonId: "ncbb-season-46",
        current: true,
        rankings: true,
      },
    ],
  },
  {
    leagueKey: "NWBL",
    seasons: [
      { seasonKey: 0, seasonId: "nwbl-season-0", current: false },
      { seasonKey: 1, seasonId: "nwbl-season-1", current: false },
      { seasonKey: 2, seasonId: "nwbl-season-2", current: false },
      { seasonKey: 3, seasonId: "nwbl-season-3", current: false },
      { seasonKey: 4, seasonId: "nwbl-season-4", current: false },
      { seasonKey: 5, seasonId: "nwbl-season-5", current: false },
      { seasonKey: 6, seasonId: "nwbl-season-6", current: false },
      { seasonKey: 7, seasonId: "nwbl-season-7", current: false },
      { seasonKey: 8, seasonId: "nwbl-season-8", current: true },
    ],
  },
  {
    leagueKey: "NBFL",
    seasons: [
      { seasonKey: 1, seasonId: "nbfl-season-1", current: false },
      { seasonKey: 2, seasonId: "nbfl-season-2", current: false },
      { seasonKey: 3, seasonId: "nbfl-season-3", current: false },
      { seasonKey: 4, seasonId: "nbfl-season-4", current: false },
      { seasonKey: 5, seasonId: "nbfl-season-5", current: false },
      { seasonKey: 6, seasonId: "nbfl-season-6", current: false },
      { seasonKey: 7, seasonId: "nbfl-season-7", current: false },
      { seasonKey: 8, seasonId: "nbfl-season-8", current: false },
      { seasonKey: 9, seasonId: "nbfl-season-9", current: false },
      { seasonKey: 10, seasonId: "nbfl-season-10", current: false },
      { seasonKey: 11, seasonId: "nbfl-season-11", current: false },
      { seasonKey: 12, seasonId: "nbfl-season-12", current: false },
      { seasonKey: 13, seasonId: "nbfl-season-13", current: false },
      { seasonKey: 14, seasonId: "nbfl-season-14", current: false },
      { seasonKey: 15, seasonId: "nbfl-season-15", current: false },
      { seasonKey: 16, seasonId: "nbfl-season-16", current: false },
      { seasonKey: 17, seasonId: "nbfl-season-17", current: false },
      { seasonKey: 18, seasonId: "nbfl-season-18", current: false },
      { seasonKey: 19, seasonId: "nbfl-season-19", current: false },
      { seasonKey: 20, seasonId: "nbfl-season-20", current: false },
      { seasonKey: 21, seasonId: "nbfl-season-21", current: false },
      { seasonKey: 22, seasonId: "nbfl-season-22", current: false },
      { seasonKey: 23, seasonId: "nbfl-season-23", current: false },
      { seasonKey: 24, seasonId: "nbfl-season-24", current: false },
      { seasonKey: 25, seasonId: "nbfl-season-25", current: false },
      { seasonKey: 26, seasonId: "nbfl-season-26", current: false },
      { seasonKey: 27, seasonId: "nbfl-season-27", current: false },
      { seasonKey: 28, seasonId: "nbfl-season-28", current: false },
      { seasonKey: 29, seasonId: "nbfl-season-29", current: false },
      { seasonKey: 30, seasonId: "nbfl-season-30", current: false },
      { seasonKey: 31, seasonId: "nbfl-season-31", current: false },
      { seasonKey: 32, seasonId: "nbfl-season-32", current: false },
      { seasonKey: 33, seasonId: "nbfl-season-33", current: true },
    ],
  },
  {
    leagueKey: "DLE",
    seasons: [
      { seasonKey: 1, seasonId: "dle-season-1", current: false },
      { seasonKey: 2, seasonId: "dle-season-2", current: false },
      { seasonKey: 3, seasonId: "dle-season-3", current: false },
      { seasonKey: 4, seasonId: "dle-season-4", current: false },
      { seasonKey: 5, seasonId: "dle-season-5", current: false },
      { seasonKey: 6, seasonId: "dle-season-6", current: true },
    ],
  },
  {
    leagueKey: "FDL",
    seasons: [{ seasonKey: 1, seasonId: "fdl-season-1", current: true }],
  },
  // {
  //   leagueKey: "GHBBL",
  //   seasons: [
  //     { seasonKey: 3, seasonId: "ghbbl-season-3", current: false },
  //     { seasonKey: 4, seasonId: "ghbbl-season-4", current: false },
  //     { seasonKey: 5, seasonId: "ghbbl-season-5", current: true },
  //   ],
  // },
  // {
  //   leagueKey: "OBBL",
  //   seasons: [{ seasonKey: 2, seasonId: "obbl-season-2", current: true }],
  // },
  // {
  //   leagueKey: "RBBL",
  //   seasons: [{ seasonKey: 2521, seasonId: "rbbl-season-2521", current: true }],
  // },
  // {
  //   leagueKey: "OBBA",
  //   seasons: [
  //     { seasonKey: 34, seasonId: "obba-season-34", current: false },
  //     { seasonKey: 35, seasonId: "obba-season-35", current: true },
  //   ],
  // },
  // {
  //   leagueKey: "145",
  //   seasons: [
  //     {
  //       seasonKey: "ShootsOfSpring",
  //       seasonId: "145-season-ShootsOfSpring",
  //       current: false,
  //       display: "Shoots of Spring",
  //     },
  //     {
  //       seasonKey: "BloomingDaffokills",
  //       seasonId: "145-season-BloomingDaffokills",
  //       current: false,
  //       display: "Blooming Daffokills",
  //     },
  //     {
  //       seasonKey: "VampsForTheMemories",
  //       seasonId: "145-season-VampsForTheMemories",
  //       current: true,
  //       display: "Vamps For the Memories",
  //     },
  //   ],
  // },
  // {
  //   leagueKey: "IoMT",
  //   seasons: [
  //     {
  //       seasonKey: "Spring2521",
  //       seasonId: "iomt-season-Spring2521",
  //       current: false,
  //       display: "Spring 2521",
  //     },
  //     {
  //       seasonKey: "Fall2521",
  //       seasonId: "iomt-season-Fall2521",
  //       current: true,
  //       display: "Fall 2521",
  //     },
  //   ],
  // },
];

export default leagueSeasons;
